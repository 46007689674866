import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Component from '../../../constants/Component';
import { SharedData } from '../../../context/CallApi';
import Slider from './Slider/Slider';


const Home = () => {


  let { bannersHome, carsSliderHome, offersHome, branchesHome, carBrandsHome, LoadingHome } = useContext(SharedData)
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('path', currentPath)
    window.scrollBy(0, -window.pageYOffset);
  }, [])
  return (
 
    <div>
      <Slider dataHome={bannersHome} />
      <Component.SearchCar branchesHome={branchesHome} carBrandsHome={carBrandsHome} />
      <Component.LatestCars carsSliderHome={carsSliderHome} />
      <Component.DownloadAppNow />
      {
        offersHome?.length >= 1 &&
        <Component.HomeOffers dataHome={offersHome} />
      }
      <Component.HomeContactUS />
      <Component.Services />
      {/* <Component.Loader /> */}

    </div>
  )
}

export default Home
