import { Player } from '@lottiefiles/react-lottie-player';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import src from '../../../assets/offers.json';
import Banner from '../../../components/Banner/Banner';
import { SharedData } from '../../../context/CallApi';
import { FiltersApis } from '../../../context/CarFiltersApis';
import { LocalizationContext } from '../../../context/LangChange';
import img from './../../../constants/Img';
import './style.scss';
import Component from '../../../constants/Component';
const Offers = () => {
  let { isLang } = useContext(LocalizationContext);
  let { offersData } = useContext(FiltersApis);
  let { configData } = useContext(SharedData);

  const bgStyle = {
    backgroundImage: `url(${img.BgOffers})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%'
  };


  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('path', currentPath)
 

  }, [])
  useEffect(() => { 
    window.scrollBy(0, -window.pageYOffset); 
  }, [])
  return (
    <div className='app__offers app__offers2 '>
      <Banner
        img={img.BgOffersPage}
        title={isLang === "en" ? 'Explore Gazelle offers ' : '   استكشف عروض الغزال'}
        dec={isLang === "en" ? 'We guarantee an easy and smooth experience through a simple booking process and reasonable prices that suit your budget. ' : 'نضمن لك تجربة سهلة وسلسة من خلال عملية حجز بسيطة وأسعار معقولة تناسب ميزانيتك. احجز اليوم واستمتع برحلة لا تُنسى!                '}
      />

      {
        offersData?.length > 0 ?
          <div className="offers pt-50 pb-50" style={bgStyle} >
            {/* {
              configData.english_offer &&
              <div className="btn_offers">
                <Button label={isLang === "en" ? configData.english_offer : configData.arabic_offer} severity="danger" />
              </div>
            } */}
            <div className='mt-50'>
              <Row className='m-auto app__offers_width'>
                {
                  offersData &&
                  offersData?.map((item, index) => (
                    <Col key={index} xl={6} className='flex justify-content-center align-items-center mt-4' lg={6} md={6} sm={12} >
                      <Link to={`/offers/details/${item.id}`} className='w-100 h-100'  >
                        <Card title={isLang === "en" ? item.title : item.title_arabic}
                          header={<img src={item.image} className='w-100 app__offer_page_img' alt="images" loading='lazy' />}>
                          <p className="m-0">
                            {isLang === "en" ? item.desc : item.desc_arabic}
                          </p>
                        </Card>
                      </Link>
                    </Col>
                  ))
                }
              </Row>

              {/*     <div className="mt-5" dir='ltr'>
            <Paginator first={first} rows={rows} totalRecords={20} onPageChange={onPageChange} />
          </div> */}
            </div>
          </div> :

          <div className="Player_offers my-8">
            <Container>

            <Component.Title title={isLang === "en"?'We currently have no offers':'لا يوجد لدينا عروض حاليا'} />
            <div className="Player">
              <Player
                className='w-100 '
                // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                src={src}
                autoplay
                loop
                direction={2000}
              />

            </div>
            </Container>
          </div>

      }


    </div >
  )
}

export default Offers
