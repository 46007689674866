// ==============================Sidebar======================================
import { ReactComponent as Bg_payment } from '../assets/Images/Bg_payment.svg';
import { ReactComponent as ServicesCars2 } from '../assets/Images/Services (1).svg';
import { ReactComponent as ServicesCars3 } from '../assets/Images/Services (2).svg';
import { ReactComponent as ServicesCars } from '../assets/Images/Services.svg';
import { ReactComponent as Blogs } from '../assets/SVG/Blogs.svg';
import { ReactComponent as Call } from '../assets/SVG/Call outline.svg';
import { ReactComponent as Call2 } from '../assets/SVG/Call2.svg';
import { ReactComponent as CallBold } from '../assets/SVG/CallBold.svg';
import { ReactComponent as Camera } from '../assets/SVG/Camera.svg';
import { ReactComponent as Clock } from '../assets/SVG/Clock.svg';
import { ReactComponent as Close } from '../assets/SVG/Close.svg';
import { ReactComponent as ContactUs } from '../assets/SVG/ContactUs.svg';
import { ReactComponent as HomeOutline } from '../assets/SVG/Home Outline.svg';
import { ReactComponent as Home } from '../assets/SVG/Home.svg';
import { ReactComponent as Jops } from '../assets/SVG/Jops.svg';
import { ReactComponent as LangOutline } from '../assets/SVG/Lang Icons.svg';
import { ReactComponent as Plus } from '../assets/SVG/Plus.svg';
import { ReactComponent as Projects } from '../assets/SVG/Projects.svg';
import { ReactComponent as Service } from '../assets/SVG/Service.svg';
import { ReactComponent as Aboutus } from '../assets/SVG/aboutus.svg';
import { ReactComponent as Bags } from '../assets/SVG/bags.svg';
import { ReactComponent as Calendar } from '../assets/SVG/calendar.svg';
import { ReactComponent as Phone } from '../assets/SVG/call.svg';
import { ReactComponent as Email } from '../assets/SVG/email.svg';
import { ReactComponent as Location } from '../assets/SVG/location.svg';
import { ReactComponent as Mada } from '../assets/SVG/mada.svg';
import { ReactComponent as Mastercard } from '../assets/SVG/mastercard.svg';
import { ReactComponent as Menu } from '../assets/SVG/menu.svg';
import { ReactComponent as Email2 } from '../assets/SVG/message outline copy.svg';
import { ReactComponent as Email3 } from '../assets/SVG/email2.svg';
import { ReactComponent as Message } from '../assets/SVG/message outline.svg';
import { ReactComponent as Mobile } from '../assets/SVG/mobile.svg';
import { ReactComponent as Rateoutlit } from '../assets/SVG/rate-outlit.svg';
import { ReactComponent as Rate } from '../assets/SVG/rate.svg';
import { ReactComponent as Visa } from '../assets/SVG/visa.svg';
import { ReactComponent as CarSpeed } from '../assets/SVG/carsspeed.svg';
import { ReactComponent as CarRent } from '../assets/SVG/car-rent.svg';

import { ReactComponent as Fax } from '../assets/SVG/Fax.svg';
import { ReactComponent as SliderCarSedan } from '../assets/SVG/car-sedan.svg';
import { ReactComponent as Chat } from '../assets/SVG/chat.svg';
import { ReactComponent as login } from '../assets/SVG/login.svg';
import { ReactComponent as SliderCarMoney } from '../assets/SVG/money-bag.svg';
import { ReactComponent as SliderCarPeople } from '../assets/SVG/people-svg.svg';
import { ReactComponent as Points } from '../assets/SVG/points.svg';
import { ReactComponent as SliderCarSoil } from '../assets/SVG/soil-temperature.svg';
import { ReactComponent as DeliveryAddress } from '../assets/SVG/حجزاتي.svg';

import { ReactComponent as Bill } from '../assets/SVG/Bill.svg';
import { ReactComponent as vill } from '../assets/SVG/vill.svg';
import { ReactComponent as thanksPages } from '../assets/SVG/thanksPages.svg';

import { ReactComponent as Upload } from '../assets/SVG/upload.svg';
import { ReactComponent as Whatsapp } from '../assets/SVG/whatsapp.svg';
import Facebook from './../assets/SVG/Facebook';
import Instagram from './../assets/SVG/Instagram';
import Linkedin from './../assets/SVG/Linkedin';
import { ReactComponent as TopArrow } from './../assets/SVG/Toplinear.svg';
import Twitter from './../assets/SVG/Twitter';
import { ReactComponent as Youtube } from './../assets/SVG/youtube.svg';

const Icons = {
    // ==============================Sidebar======================================
    Bg_payment,
    thanksPages,
    SliderCarSoil,
    Fax,
    DeliveryAddress,
    ServicesCars,
    Call2,
    Calendar,
    login,
    Points,
    Plus,
    Email3,
    Chat,
    ServicesCars2,
    ServicesCars3,
    SliderCarSedan,
    SliderCarPeople,
    SliderCarMoney,
    Menu, Camera,
    TopArrow,
    CarSpeed,
    Close,
    Home,
    Aboutus,
    ContactUs,
    Projects,
    Service,
    CallBold,
    Jops,
    Blogs,
    Facebook,
    Twitter,
    Instagram,
    Linkedin,
    Whatsapp,
    Phone,
    Email, 
    Mobile,
    Call,
    Message,
    HomeOutline,
    LangOutline,
    Rate,
    Rateoutlit,
    Upload,
    Location,
    Email2,
    Youtube,
    Clock,
    Bill,
    vill,
    Bags,
    Mastercard,
    Visa,
    Mada,
    CarRent
}

export default Icons;
